import { isEmpty } from 'lodash';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import Container from '~/components/Container';
import SectionTitle from '~/components/SectionTitle';
import Colors from '~/constants/colors';
import { RECENTLY_PRODUCT } from '~/constants/localstore';
import { useRecentlyViewProducts } from '~/data/home';
import { Product } from '~/services/product';
import ProductsSlider from '../../components/ProductsSlider';
import styles from './RecentlyView.module.scss';
import { ProductListKey } from '~/constants/enum';
import { getLocalStorage, LocalStorageKey } from '~/utils/local-storage';

type Banner = {
    image?: string;
    url?: string;
};
interface RecentlyViewProps {
    title?: string;
    image?: string;
    data?: Product[];
    banner?: {
        image?: string;
        url?: string;
    };
    skip?: boolean;
    numberOfItems?: number;
}

const RecentlyView: FC<RecentlyViewProps> = ({
    title = 'Based on your recent views',
    image,
    data = [],
    banner,
    skip,
    numberOfItems = 4
}) => {
    const isHasRecently = getLocalStorage(LocalStorageKey.RECENTLY_PRODUCT);

    const { loading, called, productItems } = useRecentlyViewProducts({ skip });

    const _banner: Banner | null = useMemo(() => {
        if (!skip && banner) {
            return banner;
        }
        return null;
    }, [banner, skip]);

    const _data: Partial<Product>[] = useMemo(() => {
        if (!!skip) {
            return data;
        }
        if (!!data.length) return data;
        return productItems;
    }, [skip, data, productItems]);

    if ((!loading && isEmpty(_data) && isEmpty(_banner)) || !isHasRecently) {
        return null;
    }

    return (
        <Container rootClassName={styles.recently_view_wrapper} className="flex flex-col">
            <SectionTitle
                text={title}
                textProps={{ type: 'headline-3', weight: 800, color: Colors.GREY_900 }}
                dividerColor={Colors.DIVIDER_COLOR}
            />

            {_banner && (
                <Link href={_banner.url || '#'}>
                    <div className="relative w-full aspect-[1296/400] lg:mb-5">
                        <img
                            className="object-cover"
                            src={_banner.image || '/images/clearance.png'}
                            alt="Clearance"
                            // fill
                            // priority={false}
                        />
                    </div>
                </Link>
            )}
            <ProductsSlider loading={loading} products={_data} title="" listkey={ProductListKey.RecentlyView} />
        </Container>
    );
};

export default RecentlyView;
